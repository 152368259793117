import { GetServerSideProps } from 'next';
import { ParsedUrlQuery } from 'querystring';
import {
  fetchStaticContent,
  getTopNav,
  fetchHeaderPromo,
  fetchHomePage,
} from '@/lib/content';
import {
  PagesContentAreas,
  Page as PageInterface,
  ContentAreas,
  HomePageResponse,
} from '@/interfaces';
import { Navigation } from '@/components/TopNav/TopNav.d';
import Page from '@/components/Page';
import {
  ContentArea1,
  HeroRegion,
  ContentAreaSkeleton,
} from '@/components/ContentAreas';
import useStatic from '@/hooks/useStatic';
import useFeature from '@/hooks/useFeature';
import { FEATURES } from '@/constants/features';
import HomePageJsonLdScript from '@/components/Seo/HomePageJsonLdScript';
import { getParamsVal } from '@/utils/urlQueryParams';
import { QueryParams } from '@/lib/content/content.d';
import MobileNavContentBlock from '@/components/MobileNavContentBlock';
import { suppressAnalyticsForBots } from '@/lib/botAnalytics';
import { getCookie } from 'cookies-next';

type Props = {
  pageData: PageInterface;
  contentAreas: PagesContentAreas;
  params: ParsedUrlQuery;
  headerPromoServerResponse: ContentAreas;
  homePageServerResponse: HomePageResponse;
  navigation: Navigation;
};

export const getServerSideProps: GetServerSideProps = async context => {
  const { req, res } = context;
  const { url } = req;

  const urlMcode = getParamsVal(url, { operation: 'get', key: 'mcode' });
  const mcode = urlMcode || getCookie('mcode', { req, res });
  suppressAnalyticsForBots(req, res);
  let currentQuerystring = getParamsVal(url, null, true);
  if (mcode) {
    currentQuerystring += `&mcode=${mcode}`;
  }
  const contentAreas = (await fetchStaticContent(
    currentQuerystring as QueryParams
  )) as PagesContentAreas;
  const headerPromoServerResponse = await fetchHeaderPromo(
    '/home_page',
    currentQuerystring as QueryParams
  );
  const navigation = await getTopNav();
  const homePageServerResponse = await fetchHomePage(
    currentQuerystring as QueryParams
  );

  return {
    props: {
      pageData: {
        name: homePageServerResponse?.name || '',
        seo: { ...(homePageServerResponse?.seo || {}) },
        // passing empty string since Page.tsx uses `${SF_URL}${pageData?.path}
        path: '',
        slug: homePageServerResponse?.slug || '',
        id: homePageServerResponse?.id || '',
      },
      navigation,
      contentAreas,
      headerPromoServerResponse,
      homePageServerResponse,
    },
  };
};

const Home = ({
  pageData,
  navigation,
  contentAreas,
  headerPromoServerResponse,
  homePageServerResponse,
}: Props): JSX.Element => {
  const staticContext = useStatic();

  Object.assign(staticContext, {
    navigation,
    contentAreas: {
      ...(contentAreas?.areas || {}),
      header_promo: {
        ...(headerPromoServerResponse?.header_promo || {}),
      },
      hero_region: [...(homePageServerResponse?.areas?.hero_region || [])],
      area_1: [...(homePageServerResponse?.areas?.area_1 || [])],
    },
    pageData,
  });
  const dataIsReady = Object.keys(pageData).length && contentAreas;
  const mobileScrollbarItems = homePageServerResponse.areas.hero_region?.find(
    region => region.type_id === 'mobile_scrollbars'
  )?.data?.mobile_scrollbars_items;

  return (
    <Page name="home-page" controlGroup="home page" showBreadcrumbs={false}>
      <HomePageJsonLdScript
        name="home-page"
        description={
          pageData.seo?.meta_description ?? 'home-page-meta-description'
        }
      />
      <MobileNavContentBlock links={mobileScrollbarItems} />
      {!dataIsReady ? <ContentAreaSkeleton /> : <HeroRegion />}
      {!dataIsReady ? <ContentAreaSkeleton /> : <ContentArea1 />}
      {/* TODO: Delete this since it only exists for testing RUI-503 in production */}
      {useFeature(FEATURES['test']) &&
        // eslint-disable-next-line no-console
        console.log(
          `You are eligible to view the test feature because you have the ${FEATURES['test']} cookie with a value of true`
        )}
    </Page>
  );
};

export default Home;
